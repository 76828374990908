import React, { useState, useEffect, FC } from 'react';
import { WidgetProps } from 'yoshi-flow-editor-runtime';
import { st, classes } from './Widget.st.css';

export type ControllerProps = {
  rcpConfig: any;
};

function protect(rcpText: string) {
  if (typeof window === 'undefined') {
    return;
  } else {
    document.addEventListener('contextmenu', event => event.preventDefault());
    const rcpSpanId = 'rcp-span';
    const rcpSpanStyle =
      'display:none; position:fixed;overflow:hidden;background-color: rgba(54,54,54,.4);border-radius:2px;color: #fff; padding: 3px 8px 3px 5px;font-family: HelveticaNeueW01-65Medi, HelveticaNeueW02-65Medi, HelveticaNeueW10-65Medi, Helvetica Neue, Helvetica, Arial, メイリオ, meiryo, ヒラギノ角ゴ pro w3, hiragino kaku gothic pro, sans-serif;font-size:11px;z-index:999999';
    const rcpScriptId = 'rcp-script';
    let rcpScript = "var rcpSpan = document.getElementById('rcp-span');";
    rcpScript +=
      "window.onmousemove = function (e) { var x = e.clientX, y = e.clientY; rcpSpan.style.top = (y + 20) + 'px'; rcpSpan.style.left = (x + 20) + 'px'; };";
    rcpScript +=
      "window.ondragstart = function (e) { if (e && e.target && (e.target.nodeName == 'IMG' || e.target.nodeName == 'img')) { return false;} };";
    rcpScript +=
      "if (document.addEventListener) { document.addEventListener('contextmenu', function(e) { showCR(); e.preventDefault(); }, false); } else { document.attachEvent('oncontextmenu', function() { showCR(); window.event.returnValue = false; }); }";
    rcpScript +=
      "function showCR() { rcpSpan.style.display = 'block'; window.setTimeout(closeCR,2000); }";
    rcpScript += "function closeCR() { rcpSpan.style.display = 'none'; }";

    const rcpStyleId = 'rcp-style';
    //pointer-events: none !important; prevents clicking on paypal button
    const rcpStyle =
      'img { user-select: none !important; -moz-user-select: none !important; -ms-user-select: none !important; -webkit-user-select: none !important; -webkit-touch-callout:none !important; -webkit-user-drag: none !important; -khtml-user-drag: none !important; -moz-user-drag: none !important; -o-user-drag: none !important; user-drag: none !important;}';

    let span = document.getElementById(rcpSpanId);
    if (!span) {
      span = document.createElement('span');
      span.id = rcpSpanId;
      span.setAttribute('style', rcpSpanStyle);
      span.innerText = rcpText;
      document.body.appendChild(span);
    } else {
      span.innerText = rcpText;
    }
    if (!document.getElementById(rcpScriptId)) {
      const script = document.createElement('script');
      script.id = rcpScriptId;
      script.type = 'text/javascript';
      script.innerHTML = rcpScript;
      document.body.appendChild(script);
    } else {
    }
    if (!document.getElementById(rcpStyleId)) {
      const style = document.createElement('style');
      style.type = 'text/css';
      style.id = rcpStyleId;
      style.innerHTML = rcpStyle;
      document.head.appendChild(style);
    } else {
    }
  }
}

const Widget: FC<WidgetProps<ControllerProps>> = (props) => {
  const rcpText = props?.rcpConfig?.t;
  useEffect(() => {
    protect(rcpText);
  });
  return (
    <div className={st(classes.root, {})} data-hook="RightClickProtect-wrapper">
      <div data-hook="rcp-text" className={classes.rcptext}>
        {rcpText}
      </div>
    </div>
  );
};

export default Widget;
