
  import WidgetWrapper from 'yoshi-flow-editor-runtime/build/esm/WidgetWrapper.js';
  import Widget from '/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/src/components/RightClickProtect/Widget/index.tsx';
  import {
    
  } from 'yoshi-flow-editor-runtime';

  import stylesParams from '/home/builduser/agent00/work/5d9827cfe03d3bf1/right-click-protect/src/components/RightClickProtect/stylesParams.ts';

  var sentryConfig = {
      DSN: 'https://261938f6d0c848dfb768387e4cb17694@sentry.wixpress.com/1142',
      id: '261938f6d0c848dfb768387e4cb17694',
      projectName: 'right-click-protect',
      teamName: 'photography',
    };

  var UserComponent = WidgetWrapper({
      
    }, Widget, {
    sentryConfig,
    stylesParams,
    name: 'RightClickProtect'
  });

  
    import { hot } from 'component-hot-loader';
    UserComponent = hot(module, UserComponent);
  

  export default {
    component: UserComponent
  };
